import { createUserWithEmailAndPassword, GoogleAuthProvider, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth } from "./firebase";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore"; // Import Firestore functions

const db = getFirestore(); // Initialize Firestore

export const doCreateUserWithEmailAndPassword = async (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
}

export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
}

// export const doSignInWithGoogle = async () => {
//   const provider = new GoogleAuthProvider();
//   const result  = await signInWithPopup(auth, provider);
//   // save result.user to firestore 
//   return result
// }

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;

  // Reference to the Firestore document
  const userDocRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(userDocRef);

  if (!userDoc.exists()) {
    console.log("saving user data:")
    // Save user data to Firestore if it doesn't exist
    await setDoc(userDocRef, {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      createdAt: new Date()
    });
  } else {
    console.log("didnt save user data")
  }

  return result;
}

export const doSignOut = () => {
  return auth.signOut()
}

// export const doPasswordReset = (email) => {
//   return sendPasswordResetEmail(auth, email)
// }

// dopasswordchange
// dosendemailverification