// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyCyIfWqdri7Nn5IPnwk4l3NjcFdl3IYQ1o",
  authDomain: "retailpal.firebaseapp.com",
  projectId: "retailpal",
  storageBucket: "retailpal.appspot.com",
  messagingSenderId: "756941947068",
  appId: "1:756941947068:web:16803c2c21881fea1690b9",
  measurementId: "G-EN5PD7TL2M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export {app, auth}